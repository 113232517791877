import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  h2 {
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 10px;
    position: relative;

    i {
      margin-right: 5px;
      color: var(--color-primary);

      &.spinoff {
        font-size: 9px;
        position: absolute;
        transform: rotate(90deg);
        top: -4px;
        margin-left: 13px;
      }
    }
  }

  p {
    margin-bottom: 10px;
    line-height: 18px;
    letter-spacing: .6px;
  }

  @media (min-width: 760px) {
    h2 {
      text-align: left;
      padding: 0 20px 10px;

      &.left {
        text-align: right;
      }

      i {
        margin-right: 10px;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export const StyledBreadcrumb = styled.ul`
  width: 100%;
  background-color: var(--color-primary);
  padding: 20px;

  li {
    margin-top: 10px;
    border-top: 1px solid;
    padding-top: 10px;

    &:first-of-type {
      margin-top: 0;
      border-top: none;
    }

    a {
      color: var(--color-black-3);
      cursor: pointer;

      i {
        margin-right: 5px;
      }
    }
  }

  @media (min-width: 760px) {
    text-align: center;

    li {
      width: auto;
      display: inline-block;
      border-top: none;
      margin: 0;
      padding: 0;

      a {
        padding: 10px;
        display: block;
      }
    }
  }
`

export const SectionContainer = styled.section`
  max-width: 1280px;
  margin: 0 auto;
`

export const StyledLogistic = styled.div`
  border-bottom: 5px solid var(--color-primary);
  background-size: auto 150px;
  background-repeat: no-repeat;
  background-position: bottom left;
  padding: 30px 15px 160px;
  margin-bottom: 50px;

  ul {
    margin: 20px 0;

    li {
      padding: 8px 10px;
      border-bottom: 1px solid var(--color-gray-light);
  
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    & > div {
      width: calc(100% - 320px);
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
      margin-top: 15px;
    }
    
    ul {
      display: inline-block;
      margin: 30px 0 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5px));
      grid-gap: 0 10px;
    }
  }

  @media (min-width: 1280px) {
    background-size: auto 385px;

    & > div {
      width: 500px;
      display: block;
      margin: 0 0 0 calc(100% - 500px);
    }

    ul {
      width: 500px;
      float: right;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-gap: 0 20px;
      text-align: left;

      li {
        &:last-of-type {
          border-bottom: 1px solid var(--color-gray-light);
        }
      }
    }
  }

  @media (min-width: 1680px) {
    & > div {
      width: 763px;
      margin: 0 0 0 calc(100% - 763px);
    }

    ul {
      width:763px;
    }
  }
`

export const ArmedEscort = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px 0;
  margin-bottom: 50px;

  h4 {
    color: var(--color-primary);
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;

    i {
      color: var(--color-black-3);
      margin-right: 10px;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 30px;
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-light);

    .tr {
      padding: 10px 10px 0;
      display: grid;
      grid-template-columns: calc(100% - 2px);
      grid-gap: 1px;

      &:nth-of-type(even) {
        background-color: var(--color-light);
      }

      & > div {
        margin-bottom: 15px;
        font-size: 18px;

        span {
          display: block;
          font-weight: 700;
          margin-bottom: 2px;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }

    @media (min-width: 760px) {
      .th {
        display: grid;
        grid-template-columns: repeat(3, calc(33.333333% - .5px));
        grid-gap: 1px;
        border-bottom: 1px solid var(--color-gray-light);
  
        div {
          padding: 0 10px;
          line-height: 35px;
          background-color: var(--color-light);
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      
      .tr {
        display: grid;
        grid-template-columns: repeat(3, calc(33.333333% - .5px));
        grid-gap: 1px;
        border-bottom: 1px solid var(--color-gray-light);
        padding: 0;

        &:last-of-type {
          border-bottom: none;
        }
        
        & > div {
          background-color: var(--color-light);
          padding: 15px 10px;
          margin: 0;
        }

        &:nth-of-type(even) {
          background-color: var(--color-gray-light);
        }

        &:hover {
          & > div {
            background-color: var(--color-warning);
          }
        }
      }
    }
  }
  
  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  iframe {
    width: 100%;
    height: 500px;
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .left-side {
      width: 400px;
      display: inline-block;
    }

    iframe {
      width: calc(100% - 420px);
      margin-left: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }

  @media (min-width: 1280px) {
    .left-side {
      width: 600px;
    }

    iframe {
      width: calc(100% - 620px);
    }
  }
`

export const ArmedEscortManagement = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;

  img {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 20px 0 0 0 var(--color-light);
  }

  h4 {
    color: var(--color-primary);
    margin: 20px 0 10px;
    border-bottom: 1px solid var(--color-black-3);
    padding-bottom: 10px;
    text-transform: uppercase;

    i {
      margin-right: 10px;
      color: var(--color-black-3);
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    img {
      width: 450px;
      vertical-align: top;
      margin-right: 20px;
      float: left;
    }

    p {
      display: inline;
    }
  }
`

export const RouteInspector = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;

  iframe {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
  }

  .destak {
    background-color: var(--color-gray-ultra-light);
    border: 1px solid var(--color-gray-dark);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  
    ul {
      list-style: disc;
      padding-left: 20px;
      margin: 20px 0;
  
      li {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .right-side {
      width: 400px;
      display: inline-block;
    }

    iframe {
      width: calc(100% - 420px);
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }

  @media (min-width: 1280px) {
    .right-side {
      width: 600px;
    }

    iframe {
      width: calc(100% - 620px);
    }
  }
`

export const InteligenceRegulation = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;
  position: relative;

  .principal {
    width: 100%;
    background-color: var(--color-black-3);
    color: var(--color-primary);
    padding: 20px 10px;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    border-radius: 5px;
    border: 3px solid var(--color-primary);
    margin-bottom: 30px;
  }

  h4 {
    background-color: var(--color-primary);
    color: var(--color-black-3);
    border: 3px solid var(--color-black-3);
    padding: 15px 10px;
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 10px;
    font-size: 19px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .first, .second, .third {
    width: 250px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: var(--color-primary);
      bottom: 100%;
      width: 3px;
    }
  }

  .first {
    margin: 0 auto 30px;

    &::before {
      height: 32px;
      left: calc(50% - 1.5px);
    }
  }

  .second {
    margin: 0 40px 30px 0;

    &::before {
      height: 267px;
      left: 10px;
    }
  }
  
  .third {
    margin: 0 0 30px calc(100% - 250px);
    
    &::before {
      height: 466px;
      right: 10px;
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .support {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--color-primary);
        right: 170px;
        top: 103px;
        height: 3px;
        width: calc(100% - 340px);
      }
    }

    .first, .second, .third {
      width: 340px;
      vertical-align: top;
    }

    .second {
      margin: 0;
      display: inline-block;

      &::before {
        height: 305px;
        left: 170px;
      }
    }

    .third {
      margin: 0;
      float: right;
      display: inline-block;

      &::before {
        height: 305px;
        right: 170px;
      }
    }

    .principal {
      width: 250px;
      margin: 0 auto 30px;
      position: relative;
    }
  }

  @media (min-width: 1280px) {
    min-height: 550px;
    .first, .second, .third {
      position: absolute;
    }

    .first {
      left: calc(50% - 170px);
      top: 240px;

      &::before {
        height: 101px;
      }
    }

    .second {
      left: 20px;
      top: 75px;

      &::before {
        display: none;
      }
    }

    .third {
      right: 20px;
      top: 75px;

      &::before {
        display: none;
      }
    }
  }
`

export const JuridicalSupport = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px 185px;
  margin-bottom: 50px;
  background-position: bottom right;
  background-size: 400px;
  background-repeat: no-repeat;

  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;
    background-size: calc(100% - 122px);
  }

  @media (min-width: 1280px) {
    background-size: 1122px;
  }
`
